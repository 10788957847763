
import { textSearch } from "@/assets/ts/_utils";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { FormulariosModule } from "@/store/modules";
import Swal from "sweetalert2";
import { ErrorMessage } from "vee-validate";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "recopilar-datos-pn2-respuestas",
  components: {
    ErrorMessage,
    "download-excel": JsonExcel,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(FormulariosModule, store);

    const csvLabels = {
      organismoNombre: t("csv.listados.respuestas.organismoNombre"),
      formularioNombre: t("csv.listados.respuestas.formularioNombre"),
      codigoRespuesta: t("csv.listados.respuestas.codigoRespuesta"),
      entidadResponsable: t("csv.listados.respuestas.entidadResponsable"),
      formulaGestionContrato: t(
        "csv.listados.respuestas.formulaGestionContrato"
      ),
      importe: t("csv.listados.respuestas.importe"),
      localizacionAcciones: t("csv.listados.respuestas.localizacionAcciones"),
      municipios: t("csv.listados.respuestas.municipios"),
      tipoBolsaCuidados: t("csv.listados.respuestas.tipoBolsaCuidados"),
      bolsaCuidadosnPlazas: t("csv.listados.respuestas.bolsaCuidadosnPlazas"),
      bolsaCuidadosnSolicitudes: t(
        "csv.listados.respuestas.bolsaCuidadosnSolicitudes"
      ),
      bolsaCuidadosnPersonasMenores3: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasMenores3"
      ),
      bolsaCuidadosnPersonas3a6: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonas3a6"
      ),
      bolsaCuidadosnPersonas7a9: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonas7a9"
      ),
      bolsaCuidadosnPersonas10a12: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonas10a12"
      ),
      bolsaCuidadosnPersonas13a16: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonas13a16"
      ),
      bolsaCuidadosnPersonasEdadNC: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasEdadNC"
      ),
      bolsaCuidadosnPersonasMujer: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasMujer"
      ),
      bolsaCuidadosnPersonasVaron: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasVaron"
      ),
      bolsaCuidadosnPersonasOtro: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasOtro"
      ),
      bolsaCuidadosnPersonasNC: t(
        "csv.listados.respuestas.bolsaCuidadosnPersonasNC"
      ),
      bolsaCuidadosnFamiliasNumerosa: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasNumerosa"
      ),
      bolsaCuidadosnFamiliasMonoparental: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasMonoparental"
      ),
      bolsaCuidadosnFamiliasRefugiada: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasRefugiada"
      ),
      bolsaCuidadosnFamiliasVictima: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasVictima"
      ),
      bolsaCuidadosnFamiliasOtrasCargas: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasOtrasCargas"
      ),
      bolsaCuidadosnFamiliasOtra: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasOtra"
      ),
      bolsaCuidadosnFamiliasNC: t(
        "csv.listados.respuestas.bolsaCuidadosnFamiliasNC"
      ),
      creacionEmpleonPlazas: t("csv.listados.respuestas.creacionEmpleonPlazas"),
      creacionEmpleonSolicitudes: t(
        "csv.listados.respuestas.creacionEmpleonSolicitudes"
      ),
      creacionEmpleonPersonasMenores30: t(
        "csv.listados.respuestas.creacionEmpleonPersonasMenores30"
      ),
      creacionEmpleonPersonas30a45: t(
        "csv.listados.respuestas.creacionEmpleonPersonas30a45"
      ),
      creacionEmpleonPersonasMas45: t(
        "csv.listados.respuestas.creacionEmpleonPersonasMas45"
      ),
      creacionEmpleonPersonasEdadNC: t(
        "csv.listados.respuestas.creacionEmpleonPersonasEdadNC"
      ),
      creacionEmpleonPersonasMujer: t(
        "csv.listados.respuestas.creacionEmpleonPersonasMujer"
      ),
      creacionEmpleonPersonasVaron: t(
        "csv.listados.respuestas.creacionEmpleonPersonasVaron"
      ),
      creacionEmpleonPersonasOtro: t(
        "csv.listados.respuestas.creacionEmpleonPersonasOtro"
      ),
      creacionEmpleonPersonasNC: t(
        "csv.listados.respuestas.creacionEmpleonPersonasNC"
      ),
      creacionEmpleonMujeresLargaDuracion: t(
        "csv.listados.respuestas.creacionEmpleonMujeresLargaDuracion"
      ),
      creacionEmpleonVaronesLargaDuracion: t(
        "csv.listados.respuestas.creacionEmpleonVaronesLargaDuracion"
      ),
      creacionEmpleonPersonasVictima: t(
        "csv.listados.respuestas.creacionEmpleonPersonasVictima"
      ),
      creacionEmpleonMujeresRefugiadas: t(
        "csv.listados.respuestas.creacionEmpleonMujeresRefugiadas"
      ),
      creacionEmpleonVaronesRefugiados: t(
        "csv.listados.respuestas.creacionEmpleonVaronesRefugiados"
      ),
      creacionEmpleonPersonasIndefinido: t(
        "csv.listados.respuestas.creacionEmpleonPersonasIndefinido"
      ),
      creacionEmpleonPersonasTemporal: t(
        "csv.listados.respuestas.creacionEmpleonPersonasTemporal"
      ),
      creacionEmpleonPersonasFijoDiscontinuo: t(
        "csv.listados.respuestas.creacionEmpleonPersonasFijoDiscontinuo"
      ),
      creacionEmpleonPersonasFormativo: t(
        "csv.listados.respuestas.creacionEmpleonPersonasFormativo"
      ),
      creacionEmpleonPersonasTipoContratoNC: t(
        "csv.listados.respuestas.creacionEmpleonPersonasTipoContratoNC"
      ),
      creacionEmpleonPersonasJornadaCompleta: t(
        "csv.listados.respuestas.creacionEmpleonPersonasJornadaCompleta"
      ),
      creacionEmpleonPersonasJornadaParcial: t(
        "csv.listados.respuestas.creacionEmpleonPersonasJornadaParcial"
      ),
      creacionEmpleonPersonasJornadaNC: t(
        "csv.listados.respuestas.creacionEmpleonPersonasJornadaNC"
      ),
      gradoAlcanceObjetivos: t("csv.listados.respuestas.gradoAlcanceObjetivos"),
      dificultadesEncontradas: t(
        "csv.listados.respuestas.dificultadesEncontradas"
      ),
      propuestasFuturas: t("csv.listados.respuestas.propuestasFuturas"),
    };

    const solicitud = computed(() => module.solicitud);
    const formulario = computed(() => module.formulario);
    const entidad = computed(() => {
      return module.solicitud?.entidadesSolicitudInformacionExterna.map(
        (ele) => {
          return {
            comarcaNombre: ele.comarca.nombre,
            comarcaEmail: ele.comarca.email,
            tipoOrganizacionNombre: ele.comarca.tipoOrganizacion.nombre,
            respondido: ele.respondido === true ? "true" : "false",
            proyectoId: ele.proyectoId,
            comarcaId: ele.comarcaId,
            solicitudInformacionExternaId: ele.solicitudInformacionExternaId,
          };
        }
      );
    });

    const respuestasFormularios = computed(() => {
      const respuestas = module.respuestas;
      return respuestas.map((r) => {
        return {
          organismoNombre: r.comarca.nombre,
          formularioNombre: module.solicitud?.formularioPublico.nombre,
          codigoRespuesta: r.codigo,
          entidadResponsable: r.datosSolicitud.entidadResponsable,
          formulaGestionContrato: r.datosSolicitud.formulaGestionContrato,
          importe: r.datosSolicitud.importe,
          localizacionAcciones: r.datosSolicitud.localizacionAcciones,
          municipios: r.datosSolicitud.municipios
            .map((m) => m.municipioNombre)
            .join(", "),
          tipoBolsaCuidados: r.datosSolicitud.bolsaCuidados.tipoBolsaCuidados
            .map((tbc) => t("csv.listados.respuestas." + tbc))
            .join(", "),
          bolsaCuidadosnPlazas: r.datosSolicitud.bolsaCuidados.nPlazas,
          bolsaCuidadosnSolicitudes:
            r.datosSolicitud.bolsaCuidados.nSolicitudes,
          bolsaCuidadosnPersonasMenores3:
            r.datosSolicitud.bolsaCuidados.nPersonasMenores3,
          bolsaCuidadosnPersonas3a6:
            r.datosSolicitud.bolsaCuidados.nPersonas3a6,
          bolsaCuidadosnPersonas7a9:
            r.datosSolicitud.bolsaCuidados.nPersonas7a9,
          bolsaCuidadosnPersonas10a12:
            r.datosSolicitud.bolsaCuidados.nPersonas10a12,
          bolsaCuidadosnPersonas13a16:
            r.datosSolicitud.bolsaCuidados.nPersonas13a16,
          bolsaCuidadosnPersonasEdadNC:
            r.datosSolicitud.bolsaCuidados.nPersonasEdadNC,
          bolsaCuidadosnPersonasMujer:
            r.datosSolicitud.bolsaCuidados.nPersonasMujer,
          bolsaCuidadosnPersonasVaron:
            r.datosSolicitud.bolsaCuidados.nPersonasVaron,
          bolsaCuidadosnPersonasOtro:
            r.datosSolicitud.bolsaCuidados.nPersonasOtro,
          bolsaCuidadosnPersonasNC: r.datosSolicitud.bolsaCuidados.nPersonasNC,
          bolsaCuidadosnFamiliasNumerosa:
            r.datosSolicitud.bolsaCuidados.nFamiliasNumerosa,
          bolsaCuidadosnFamiliasMonoparental:
            r.datosSolicitud.bolsaCuidados.nFamiliasMonoparental,
          bolsaCuidadosnFamiliasRefugiada:
            r.datosSolicitud.bolsaCuidados.nFamiliasRefugiada,
          bolsaCuidadosnFamiliasVictima:
            r.datosSolicitud.bolsaCuidados.nFamiliasVictima,
          bolsaCuidadosnFamiliasOtrasCargas:
            r.datosSolicitud.bolsaCuidados.nFamiliasOtrasCargas,
          bolsaCuidadosnFamiliasOtra:
            r.datosSolicitud.bolsaCuidados.nFamiliasOtra,
          bolsaCuidadosnFamiliasNC: r.datosSolicitud.bolsaCuidados.nFamiliasNC,
          creacionEmpleonPlazas: r.datosSolicitud.creacionEmpleo.nPlazas,
          creacionEmpleonSolicitudes:
            r.datosSolicitud.creacionEmpleo.nSolicitudes,
          creacionEmpleonPersonasMenores30:
            r.datosSolicitud.creacionEmpleo.nPersonasMenores30,
          creacionEmpleonPersonas30a45:
            r.datosSolicitud.creacionEmpleo.nPersonas30a45,
          creacionEmpleonPersonasMas45:
            r.datosSolicitud.creacionEmpleo.nPersonasMas45,
          creacionEmpleonPersonasEdadNC:
            r.datosSolicitud.creacionEmpleo.nPersonasEdadNC,
          creacionEmpleonPersonasMujer:
            r.datosSolicitud.creacionEmpleo.nPersonasMujer,
          creacionEmpleonPersonasVaron:
            r.datosSolicitud.creacionEmpleo.nPersonasVaron,
          creacionEmpleonPersonasOtro:
            r.datosSolicitud.creacionEmpleo.nPersonasOtro,
          creacionEmpleonPersonasNC:
            r.datosSolicitud.creacionEmpleo.nPersonasNC,
          creacionEmpleonMujeresLargaDuracion:
            r.datosSolicitud.creacionEmpleo.nMujeresLargaDuracion,
          creacionEmpleonVaronesLargaDuracion:
            r.datosSolicitud.creacionEmpleo.nVaronesLargaDuracion,
          creacionEmpleonPersonasVictima:
            r.datosSolicitud.creacionEmpleo.nPersonasVictima,
          creacionEmpleonMujeresRefugiadas:
            r.datosSolicitud.creacionEmpleo.nMujeresRefugiadas,
          creacionEmpleonVaronesRefugiados:
            r.datosSolicitud.creacionEmpleo.nVaronesRefugiados,
          creacionEmpleonPersonasIndefinido:
            r.datosSolicitud.creacionEmpleo.nPersonasIndefinido,
          creacionEmpleonPersonasTemporal:
            r.datosSolicitud.creacionEmpleo.nPersonasTemporal,
          creacionEmpleonPersonasFijoDiscontinuo:
            r.datosSolicitud.creacionEmpleo.nPersonasFijoDiscontinuo,
          creacionEmpleonPersonasFormativo:
            r.datosSolicitud.creacionEmpleo.nPersonasFormativo,
          creacionEmpleonPersonasTipoContratoNC:
            r.datosSolicitud.creacionEmpleo.nPersonasTipoContratoNC,
          creacionEmpleonPersonasJornadaCompleta:
            r.datosSolicitud.creacionEmpleo.nPersonasJornadaCompleta,
          creacionEmpleonPersonasJornadaParcial:
            r.datosSolicitud.creacionEmpleo.nPersonasJornadaParcial,
          creacionEmpleonPersonasJornadaNC:
            r.datosSolicitud.creacionEmpleo.nPersonasJornadaNC,
          gradoAlcanceObjetivos: r.datosSolicitud.gradoAlcanceObjetivos,
          dificultadesEncontradas: r.datosSolicitud.dificultadesEncontradas,
          propuestasFuturas: r.datosSolicitud.propuestasFuturas,
        };
      });
    });

    const respuestas = computed(() => {
      return (
        module.solicitud?.entidadesSolicitudInformacionExterna.filter(
          (ele) => ele.respondido === true
        ).length || 0
      );
    });

    const solicitudes = computed(() => {
      return module.solicitudesListado?.map((ele) => {
        return {
          ...ele,
        };
      });
    });

    // Busqueda y ordenación.
    const search = ref("");
    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });
    const sortChange = ({ column, prop, order }) => {
      sortProps.value = { prop, order, method: column.sortMethod };
    };
    const filteredCollection = computed(function () {
      return solicitudes.value
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value || textSearch(data.denominacion, search.value)
            // TODO: añadir más campos a la búsqueda
          );
        });
    });

    // Paginación.
    const { currentPage } = useArrayPagination(filteredCollection, {
      pageSize: 100,
    });
    const handleCurrentPageChange = (page: number) =>
      (currentPage.value = page);

    const cargaListado = ref(false);
    const load = async () => {
      cargaListado.value = true;
      try {
        await module.fetchAllSolicitudes();
        await module.fetchRespuestas(module.solicitud?.id as string);
        search.value = "";
        currentPage.value = 1;
      } catch (error) {
        Swal.fire({
          text: t("formularios.encuestasListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    };

    const admiteRespuestas = ref<boolean>(false);
    const cambiarEstadoAdmision = async () => {
      if (solicitud.value) {
        if (solicitud.value.admiteRespuestas === true) {
          admiteRespuestas.value = false;
        } else if (solicitud.value.admiteRespuestas === false) {
          admiteRespuestas.value = true;
        }

        try {
          await module.updateById({
            id: solicitud.value?.id,
            solicitud: { admiteRespuestas: admiteRespuestas.value },
          });

          Swal.fire({
            text: t("formularios.cambiarEstado.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } catch (error) {
          Swal.fire({
            text: t("formularios.cambiarEstado.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        if (solicitud.value.id) {
          await module.fetchById({ id: solicitud.value.id });
        }

        solicitud.value.admiteRespuestas = admiteRespuestas.value;
        return admiteRespuestas.value;
      }
    };

    watch(admiteRespuestas, () => {
      cambiarEstadoAdmision();
    });

    const reenviarCorreo = async (index, row) => {
      if (module.solicitud?.admiteRespuestas === false) {
        return;
      }
      try {
        await module.reenviar({
          id: row.solicitudInformacionExternaId,
          comarcaId: row.comarcaId,
        });

        Swal.fire({
          text: t("formularios.reenviarCorreo.exito"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } catch (error) {
        Swal.fire({
          text: t("formularios.reenviarCorreo.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handleEdit = (index, row) => {
      router.push(`/listados/recoleccion-de-datos/detalle/${row.proyectoId}`);
    };

    const sincronizarConHub = async () => {
      cargaListado.value = true;
      try {
        await module.sincronizarConHub(module.solicitud?.id as string);
        Swal.fire({
          text: t("formularios.sincronizacionConHub.exito"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } catch (error) {
        Swal.fire({
          text: t("formularios.sincronizarConHub.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    };

    onBeforeMount(() => {
      load();
    });
    onMounted(() =>
      setCurrentPageBreadcrumbs(t("menu.recogidaDatos"), [
        t("menu.planCorresponsables"),
      ])
    );

    const csvName = "respuestas" + new Date().getTime() + ".xlsx";

    return {
      solicitud,
      sincronizarConHub,
      reenviarCorreo,
      handleEdit,
      handleCurrentPageChange,
      sortChange,
      entidad,
      respuestas,
      currentPage,
      cargaListado,
      admiteRespuestas,
      cambiarEstadoAdmision,
      respuestasFormularios,
      csvLabels,
      csvName,
      formulario,
    };
  },
});
